<template>
  <div>
    <title>SIM INVENTORY ~ DATA LIST OF GENERAL JOURNAL DETAILS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Data List of General Journal Details
        <br />
        <h4>
          The following is a list of transaction data lists of general journal
          details
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
          <!-- general form elements -->
          <div class="col-xs-12">
            <div class="box box-primary">
              <div class="box-header with-border">
                <i class="fa fa-save"></i>
                <h3 class="box-title">Save General Journal Detail Data</h3>
              </div>
              <!-- /.box-header -->
              <!-- form start -->
              <form role="form">
                <div class="box-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label for="exampleInputEmail1" class="col-md-2 col-form-label">COA</label>
                        <input type="hidden" name="id" v-model="id">
                        <div class="col-md-10">
                          <select2
                            :data="coalist"
                            :value="valuecoa"
                            @update="updatecoa($event)"
                          ></select2>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="exampleInputEmail1" class="col-md-2 col-form-label">Notes</label>
                        <div class="col-md-10">
                          <input
                            type="text"
                            v-model="isidata.notesjud"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Notes"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="exampleInputEmail1" class="col-md-2 col-form-label">Reference</label>
                        <div class="col-md-10">
                          <input
                            type="text"
                            v-model="isidata.refjud"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Reference"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label for="exampleInputEmail1" class="col-md-2 col-form-label">Debit</label>
                        <div class="col-md-10">
                          <input
                            type="text"
                            v-model="isidata.debetjud"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Debit"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="exampleInputEmail1" class="col-md-2 col-form-label">Credit</label>
                        <div class="col-md-10">
                          <input
                            type="text"
                            v-model="isidata.kreditjud"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Credit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-footer">
                  <button
                    @click="saveData()"
                    type="button"
                    class="btn btn-primary"
                  >
                    <i class="fa fa-save"></i>
                    Save
                  </button>
                  &nbsp;
                  <router-link to="/jurnalumum">
                    <button class="btn btn-success">
                      <i class="fa fa-angle-double-left"></i> Back
                    </button>
                  </router-link>
                </div>
              </form>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">
                  General Journal Details
                  <b style="color: red"> {{ nobuk }} </b>
                </h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>

                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <input
                type="hidden"
                class="form-control"
                readonly
                name="nobuk"
                v-model="nobuk"
                id="nobuk"
              />
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>COA Code</th>
                      <th>COA Name</th>
                      <th>Reference</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.coa_code }}</td>
                      <td>{{ classdata.coa_name }}</td>
                      <td>{{ classdata.referensi }}</td>
                      <td>Rp. {{ formatPrice(classdata.debet) }}</td>
                      <td>Rp. {{ formatPrice(classdata.kredit) }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                          <button
                            title="Edit general journal detail"
                            class="btn btn-info"
                            @click="edit(classdata)"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        &nbsp;
                        <button
                          title="Delete general journal detail"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <!-- <td></td> -->
                      <td></td>
                      <td></td>
                      <td><b style="color:red"> Rp. {{formatPrice(totalDebet(list_paged))}} </b></td>
                      <td><b style="color:teal"> Rp. {{formatPrice(totalKredit(list_paged))}} </b></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>COA Code</th>
                      <th>COA Name</th>
                      <th>Reference</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="7" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isExist: true,
      isShow: false,
      loading: false,
      isidata: {
        id: "",
        notesjud: "",
        refjud: "",
        debetjud: "",
        kreditjud: "",
      },
      coalist: [],
      valuecoa: "",
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      produkno: "",
      pageSizes: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
    this.getCOAList();
    this.nobuk = this.$route.params.id;
  },
  methods: {
    updatecoa(value) {
      this.valuecoa = value;
    },
    getCOAList() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "coa/getalldatacoa?length=5000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.coalist = resp.data.data;
          this.coalist.forEach((item) => {
            item.value = item.coa_code;
            item.label = item.coa_name;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    async saveData() {
      this.loading = true;
      var coacode = this.valuecoa;
      var catat = this.isidata.notesjud;
      if (coacode == "" || catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "COA Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");

        const paramdatadetail = {
          no_bukti: this.nobuk,
          coa_code: this.valuecoa,
          notes: this.isidata.notesjud,
          kode_user: kodeuser,
          referensi: this.isidata.refjud,
          debet: this.isidata.debetjud,
          kredit: this.isidata.kreditjud
        };

        const tokenlogin = sessionStorage.getItem("token");
        let urlAPIsavedatadet = "";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        let ax=axios;
        if(this.isidata.id!=""){
          urlAPIsavedatadet=this.$apiurl + "jurnal_umum_d/updatejurnal_umum_d/" + this.isidata.id;
          ax = axios.put(urlAPIsavedatadet, paramdatadetail, { headers: headers })
        }else{
          urlAPIsavedatadet=this.$apiurl + "jurnal_umum_d/savejurnal_umum_d";
          ax = axios.post(urlAPIsavedatadet, paramdatadetail, { headers });
        }
        ax
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.valuecoa = "";
              this.isidata.id = "";
              this.isidata.notesjud = "";
              this.isidata.refjud = "";
              this.isidata.debetjud = "";
              this.isidata.kreditjud = "";
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    edit(data){
      console.log(data);
      console.log(this.isidata)
      this.isidata.id = data.id;
      this.valuecoa = data.coa_code
      this.isidata.notesjud = data.notes;
      this.isidata.refjud = data.referensi;
      this.isidata.debetjud = data.debet;
      this.isidata.kreditjud = data.kredit;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    totalDebet(values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.debet);
      }, 0);
    },
    totalKredit(values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.kredit);
      }, 0);
    },
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_d/getalldatajurnal_umum_d?length="+this.pageSize+"&page="+this.page+"&no_bukti=" + this.$route.params.id
      const urlApiGETdetail =
        this.$apiurl +
        "jurnal_umum_d/getalldatajurnal_umum_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&no_bukti=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            console.log(this.list_paged);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data.pesan,
          });
        });
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        window.location.reload();
        this.loading = true;
        this.fetchData();
      } else {
        const tokenlogin = sessionStorage.getItem("token");
        // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_d/getalldatajurnal_umum_d?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page+"&id_produk_d=" + this.$route.params.id
        const urlApiGETdetail =
          this.$apiurl +
          "jurnal_umum_d/getalldatajurnal_umum_d?length=" +
          this.pageSize +
          "&cari=" +
          this.namesearch +
          "&page=" +
          this.page +
          "&no_bukti=" +
          this.$route.params.id;
        axios
          .get(urlApiGETdetail, {
            paramsst,
            headers: { Authorization: tokenlogin },
          })
          .then((resp) => {
            if (resp.data.data.length == 0) {
              this.isExist = false;
            } else {
              this.isExist = true;
              this.list_paged = resp.data.data;
              this.count = resp.data.data.datatotalcount;
            }
            this.loading = false;
          })
          .catch((err) => {
            // console.log(err.response);
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
            });
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_d/hapusjurnal_umum_d/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "jurnal_umum_d/hapusjurnal_umum_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
